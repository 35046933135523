import logo from "./logo.svg";
import "./App.css";
import "./index.css";
import Income from "./components/Income";
import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Switch, } from "react-router-dom";
import Assets from "./components/Assets";
import InsuranceProfile from "./components/InsuranceProfile";
import AppProvider from "./components/AppProvider";
import Critical_illness from "./components/Critical_illness";
import Corporate_insurance from "./components/Corporate_insurance";
import Familydetails from "./components/Familydetails";
import Health_insurance from "./components/Health_insurance";
import Cyber_insurance from "./components/Cyber_insurance";
import Home_insurance from "./components/Home_insurance";
import Personal_insurance_acc from "./components/Personal_insurance_acc";
import Super_topup from "./components/Super_topup";
import Insurancedetails from "./components/Insurancedetails";
import Onboarding from "./components/Onboarding";
import Recommendations from "./components/Recommendation";
import Login from "./components/Login";
import Sl from "./components/Sl";
import Error from "./components/Error";
import PersonalDetails from "./components/PersonalDetails";
import Libility from "./components/Libilities";
import City from "./components/City";
import OTPvalidation from "./components/Otpvalidation";
import Twowheeler from "./components/TwowheelerMotorDetails";
import Fourwheeler from "./components/fourwheelerMotorDetails";
import Motor from "./components/Motor";
import Terms from "./components/Terms_condtions.js";
import Twowheeler_motor from "./components/Twowheeler_motor";
import { Private } from "./Routes/Privateroutes";
import { Restricted } from "./Routes/Restricted";
import { GA_trigger } from "./config/ga";
import Lets_get_personal_screen from "./components/LetsGetPersonalScreen";
import LetsGetPersonalScreen from "./components/LetsGetPersonalScreen";
import LaFamiliaScreen from "./components/LaFamiliaScreen";
import AllThingsMoneyScreen from "./components/AllThingsMoneyScreen";
import { access_checking } from "../src/commonfunctions/commonfunction";
import ProtectedRoutes from "./ProtectedRoutes/ProtectedRoutes";
import UnproctedRoutes from "./ProtectedRoutes/UnproctedRoutes";

function App() {
  
  useEffect(() => {
    GA_trigger.GA();
  }, []);
  return (
    <div>
      {/* <div class="header">
        <img src="lOGO CREATIVES-09.png" />
      </div> */}
      <div className="App">
        <div class="Mainapp">
          <BrowserRouter basename="/kompass">
            <AppProvider>
              <Switch>
                <UnproctedRoutes path="/" component={Onboarding} exact />
                <UnproctedRoutes path="/login" component={Login} exact />
                <ProtectedRoutes
                  path="/income"
                  component={Income}
                  
                  exact
                />  
                <ProtectedRoutes path="/assets" component={Assets} />
                <ProtectedRoutes
                  path="/insurance-profile"
                  component={InsuranceProfile}
                  exact
                />
                <ProtectedRoutes
                  path="/critical-illness"
                  component={Critical_illness}
                  exact
                />
                <ProtectedRoutes
                  path="/corporate-insurance"
                  component={Corporate_insurance}
                  exact
                />
                <ProtectedRoutes path="/family-details" component={Familydetails} exact />
                <ProtectedRoutes
                  path="/health-insurance"
                  component={Health_insurance}
                  exact
                />
                <ProtectedRoutes
                  path="/cyber-insurance"
                  component={Cyber_insurance}
                  exact
                />
                <ProtectedRoutes
                  path="/home-insurance"
                  component={Home_insurance}
                  exact
                />
                <ProtectedRoutes
                  path="/personal-accident-insurance"
                  component={Personal_insurance_acc}
                  exact
                />
                <ProtectedRoutes path="/super-topup" component={Super_topup} exact />
                <ProtectedRoutes
                  path="/insurance-details"
                  component={Insurancedetails}
                  exact
                />
                <UnproctedRoutes path="/onboarding" component={Onboarding} exact />

                 {/* FILLER SCFEENS START*/}

                 <ProtectedRoutes
                  path="/letsGetPersonal"
                  component={LetsGetPersonalScreen}
                  exact
                  />
                 <ProtectedRoutes
                  path="/laFamilia"
                  component={LaFamiliaScreen}
                  exact
                  />
                 <ProtectedRoutes
                  path="/allthing-money"
                  component={AllThingsMoneyScreen}
                  exact
                  />

                  {/* FILLER SCFEENS END */}
                <ProtectedRoutes
                  path="/recommendations"
                  component={Recommendations}
                  exact
                />
                <ProtectedRoutes path="/sl" component={Sl} exact />
                <ProtectedRoutes
                  path="/personal_details"
                  component={PersonalDetails}
                  exact
                />
                <ProtectedRoutes path="/libility" component={Libility} exact />
                <UnproctedRoutes path="/validation" component={OTPvalidation} exact />
                <ProtectedRoutes path="/Motordetails" component={Fourwheeler} exact />
                <ProtectedRoutes path="/bikedetails" component={Twowheeler} exact />
                <ProtectedRoutes path="/city" component={City} exact />
                <ProtectedRoutes
                  path="/Twowheeler_motor"
                  component={Twowheeler_motor}
                  exact
                />
                <ProtectedRoutes
                  path="/Motor_details"
                  type="BIKE"
                  component={Motor}
                  exact
                />
                <ProtectedRoutes path="/terms&conditions" component={Terms} exact />
                <ProtectedRoutes component={Error} exact />
               
              </Switch>
            </AppProvider>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}

export default App;
